@tailwind base;
@tailwind components;
@tailwind utilities;


.img_background {
	background-image: url('../public/guild_hall.jpg');
	background-size: 100% 100%;
	background-repeat: no-repeat;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.fondamento-regular {
	font-family: "Fondamento", cursive;
	font-weight: 400;
	font-style: normal;
}

.fondamento-regular-italic {
	font-family: "Fondamento", cursive;
	font-weight: 400;
	font-style: italic;
}

.jacquard-24-regular {
	font-family: "Jacquard 24", system-ui;
	font-weight: 400;
	font-style: normal;
}

.jaini-regular {
	font-family: "Jaini", system-ui;
	font-weight: 400;
	font-style: normal;
}

.sedan-sc-regular {
	font-family: "Sedan SC", serif;
	font-weight: 400;
	font-style: normal;
}

.playfair-display-sc-regular {
	font-family: "Playfair Display SC", serif;
	font-weight: 400;
	font-style: normal;
}

.playfair-display-sc-bold {
	font-family: "Playfair Display SC", serif;
	font-weight: 700;
	font-style: normal;
}

.playfair-display-sc-black {
	font-family: "Playfair Display SC", serif;
	font-weight: 900;
	font-style: normal;
}

.playfair-display-sc-regular-italic {
	font-family: "Playfair Display SC", serif;
	font-weight: 400;
	font-style: italic;
}

.playfair-display-sc-bold-italic {
	font-family: "Playfair Display SC", serif;
	font-weight: 700;
	font-style: italic;
}

.playfair-display-sc-black-italic {
	font-family: "Playfair Display SC", serif;
	font-weight: 900;
	font-style: italic;
}

.sedan-sc-regular {
	font-family: "Sedan SC", serif;
	font-weight: 400;
	font-style: normal;
}

.platypi-500 {
	font-family: "Platypi", serif;
	font-optical-sizing: auto;
	font-weight: 500;
	font-style: normal;
}